<template>

  <div
    @click="$emit('cancel')"
    class="flex items-center justify-center w-full h-full"
  >
    <div
      @click="blockPropogation"
      class="flex flex-col w-3/4"
    >
      <date-picker
        :value="date"
        @input="$emit('update:date', $event)"
        :disabled-dates="busyDates"
        :rows="2"
        :is-dark="true"
        is-expanded
        :masks="{ input: 'YYYY-MM-DD' }"
        class="self-center"
      />

    </div>
  </div>

</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

export default {
  name: 'AssistanceBookingDatePickerModal',
  components: {
    DatePicker,
  },
  props: {
    date: {
      type: Date,
      required: true,
    },
    busyDates: {
      type: Array,
      required: true,
    },
  },
  watch: {
    date() {
      this.$emit('cancel');
    },
  },
  methods: {
    blockPropogation(event) {
      event.stopPropagation();
    },
  },
};
</script>
